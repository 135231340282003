import dashboard from "./menu/dashboard";
import oppHeader from "./menu/oppHeader";
import region from "./menu/region";
import agent from "./menu/agent";
import outlet from "./menu/outlet";
import product from "./menu/product";
import order from "./menu/order";
import paymentMethod from "./menu/paymentMethod";
import humanResourceHeader from "./menu/humanResourceHeader";
import attendance from "./menu/attendance";
import configurationHeader from "./menu/configurationHeader";
import saleperson from "./menu/saleperson";
import user from "./menu/user";
import report from "./menu/report";
import configuration from "./menu/configuration";
import item from "./menu/item";
import noOrderReason from "./menu/noOrderReason";
import smiaResourceHeader from "./menu/smiaResourceHeader";
import news from "./menu/news";
import outletDraft from "./menu/outletDraft";
import callPlan from "./menu/callPlan";
import leave from "./menu/leave";
import holiday from "./menu/holiday";
import survey from "./menu/survey";
import outletFeedbackSurvey from "./menu/outletFeedbackSurvey";
import syncErpData from "./menu/syncErpData";

export default [
  ...dashboard,
  ...oppHeader,
  ...order,
  ...paymentMethod,
  ...report,
  ...outletFeedbackSurvey,
  ...smiaResourceHeader,
  ...callPlan,
  ...news,
  ...outletDraft,
  ...humanResourceHeader,
  ...attendance,
  ...leave,
  ...holiday,
  ...saleperson,
  ...configurationHeader,
  ...region,
  ...agent,
  ...outlet,
  ...product,
  ...item,
  ...survey,
  ...noOrderReason,
  ...syncErpData,
  ...user,
  ...configuration,
]
